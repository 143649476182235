<template>
    <div>
        <ts-page-title
            :title="$t('shopCompensation.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('shopCompensation.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <a-button
                        type="primary"
                        @click="addNew"
                        v-if="$can('create-shop-compensations')"
                    >
                        {{ $t("addNew") }}
                    </a-button>
                </div>
            </ts-panel-wrapper>
						<a-tabs :default-active-key="active_tab" @change="onTabChange">
                <a-tab-pane :key="1" :tab="$t('shopCompensation.allData')" force-render>
                  <ClearanceHistory ref="history" />
                </a-tab-pane>
            </a-tabs>
        </ts-panel>
    </div>
</template>

<script>
import ClearanceHistory from './components/clearance-history.vue'
export default {
    name: "shopCompensateIndex",
		components: {
			ClearanceHistory,
		},
    data() {
        return {
					active_tab: 1
				};
    },
		methods: {
			addNew(){
				this.$router.push({ name: 'shop-compensation-create'})
			},
				onTabChange(index){
					if(index == 1)
					{
						this.$refs.history.fetchData();
					}
				}
		},
		beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.history.fetchData();
        });
    },
};
</script>
